<template>
  <b-card
      :style="
			$store.state.appConfig.layout.navbar.type === 'hidden'
				? 'top: 2rem; right: 2rem'
				: 'top: 6rem; right: 2rem'
		"
      class="settings-topbar position-fixed d-inline-flex rounded p-1 shadow"
      no-body
      style="z-index: 100"
  >

    <div class="d-inline-flex">
      <!--全局时间配置-->
      <template v-if="false">
        <div class="d-inline-flex">
          <b-form-checkbox
              v-model="group_topbar_settings.group_time_query_active"
              style="margin: 0.35rem 0.5rem"
              switch
          >
          </b-form-checkbox>
          <div
              v-if="group_topbar_settings.group_time_query_active"
              class="h-100"
              style="width: 12rem"
          >
            <div
                v-if="group_topbar_settings.group_time_query_type === 1"
                class="h-100 w-100"
            >
              <div
                  class="h-50 w-100 text-primary"
                  style="line-height: 15px; font-size: 0.8rem"
              >
                起：{{ group_topbar_settings.group_time_query_start }}
              </div>
              <div
                  class="h-50 w-100 text-primary"
                  style="line-height: 15px; font-size: 0.8rem"
              >
                止：{{ group_topbar_settings.group_time_query_end }}
              </div>
            </div>
            <div
                v-else-if="group_topbar_settings.group_time_query_type === 2"
                class="h-100 w-100 text-primary"
                style="line-height: 30px"
            >
              最近 {{ group_topbar_settings.group_time_query_length }}
              {{ group_topbar_settings.group_time_query_unit }}
            </div>
          </div>
          <div v-else class="h-100" style="width: 12rem; line-height: 30px">
            全局时间
          </div>
          <b-modal
              id="modal-global-daterange"
              v-model="group_time_query_modal"
              cancel-title="取消"
              cancel-variant="outline-secondary"
              ok-title="确定"
              button-size="sm"
              title="设定全局时间区间"
          >
            <b-form>
              <b-form-group>
                <label>区间类型</label>
                <v-select
                    id="time_query_type"
                    v-model="group_topbar_settings.group_time_query_type"
                    :class="
								!group_topbar_settings.group_time_query_type
									? 'border-danger'
									: ''
							"
                    :options="[
								{ text: '精确区间', value: 1 },
								{ text: '模糊区间', value: 2 },
							]"
                    :reduce="(text) => text.value"
                    class="rounded"
                    label="text"
                />
              </b-form-group>
              <b-form-group>
                <label>时间区间</label><br/>
                <template v-if="group_topbar_settings.group_time_query_type === 1">
                  <b-form-input
                      v-model="group_topbar_settings.group_time_query_start"
                      class="d-inline-block"
                      placeholder="2020-01-01 00:00:00"
                      style="width: 45%"
                  />
                  <div
                      class="d-inline-block text-center"
                      style="width: 10%"
                  >
                    ~
                  </div>
                  <b-form-input
                      v-model="group_topbar_settings.group_time_query_end"
                      class="d-inline-block"
                      placeholder="2020-01-31 23:59:59"
                      style="width: 45%"
                  />
                </template>
                <template v-else>
                  <div class="d-inline-block" style="width: 20%">
                    <label class="ma-0">最近</label>
                  </div>
                  <b-form-input
                      v-model="group_topbar_settings.group_time_query_length"
                      class="d-inline-block"
                      placeholder="3"
                      style="width: 45%; margin-right: 5%"
                      type="number"
                  />
                  <b-form-select
                      v-model="group_topbar_settings.group_time_query_unit"
                      :options="time_query_unit_list"
                      class="d-inline-block"
                      style="width: 30%"
                  />
                </template>
              </b-form-group>
            </b-form>
          </b-modal>
        </div>

      </template>

      <template>
        <div class="border-left border-right mr-1 pr-1 pl-50">
          <div class="d-inline-block">
            <b-button
                v-b-tooltip.hover
                v-b-modal:modal-group-auto-refresh
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-icon settings-topbar-btns"
                size="sm"
                title="自动刷新数据"
                variant="success"
            >
              <feather-icon icon="RefreshCwIcon"/>
            </b-button>
          </div>
          <div class="d-inline-block pl-50" style="line-height: 30px">
            自动刷新 :
          </div>
          <div class="d-inline-block pl-50" style="width: 3.5rem;">
            <div v-if="group_topbar_settings.group_auto_refresh_active" class="position-absolute"
                 style="width: 3.5rem;height:30px;top: 0.8rem;">
              <div style="height: 15px"><span>{{ group_auto_refresh_count_down }}秒</span></div>
              <div style="height: 15px"><span class="text-success">{{
                  group_topbar_settings.group_auto_refresh_interval
                }}秒</span></div>
            </div>
            <div v-else style="line-height: 30px;width: 3.5rem;">
              未开启
            </div>
          </div>


          <b-modal
              id="modal-group-auto-refresh"
              cancel-title="取消"
              cancel-variant="outline-secondary"
              ok-title="确定"
              button-size="sm"
              title="设定自动刷新数据"
              @ok="updateGroupAutoRefreshSettings"
          >
            <b-form>
              <b-form-group
                  label-cols-sm="4"
                  content-cols-sm="8"
                  label="开启自动刷新"
              >
                <div style="width: 100%">
                  <div class="d-inline-block pr-50" style="width: 6.5rem;">
                    <span style="line-height: 24px;font-size: 1rem">
                      {{ group_topbar_settings.group_auto_refresh_active ? '已开启' : '未开启' }}
                    </span>
                  </div>
                  <div class="d-inline-block">
                    <b-form-checkbox
                        v-model="group_topbar_settings.group_auto_refresh_active"
                        style="margin: 0.35rem 0.5rem"
                        switch
                    >
                    </b-form-checkbox>
                  </div>

                </div>

              </b-form-group>
              <b-form-group
                  label-cols-sm="4"
                  content-cols-sm="8"
                  label="自动刷新间隔"
              >
                <b-input-group>
                  <template #prepend>
                    <div class="pr-50" style="width: 6rem;line-height: 38px;">
                      {{ group_topbar_settings.group_auto_refresh_interval }} 秒
                    </div>
                  </template>
                  <b-form-input v-model="group_topbar_settings.group_auto_refresh_interval" type="range" min="60"
                                max="3600"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-form>
          </b-modal>
        </div>

      </template>


      <!--顶部按钮组-->
      <b-button
          v-b-tooltip.hover
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="btn-icon settings-topbar-btns"
          size="sm"
          title="手动刷新数据"
          variant="info"
          @click="refreshQueryData"
      >
        <feather-icon icon="RotateCwIcon"/>
      </b-button>
      <!--			<b-button-->
      <!--				v-b-tooltip.hover-->
      <!--				v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
      <!--				class="btn-icon settings-topbar-btns"-->
      <!--				size="sm"-->
      <!--				title=""-->
      <!--				variant="danger"-->
      <!--			>-->
      <!--				<feather-icon icon="LockIcon" />-->
      <!--			</b-button>-->
      <b-button
          v-b-tooltip.hover
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :variant="
					$store.state.appConfig.layout.menu.hidden
						? 'primary'
						: 'secondary'
				"
          class="btn-icon settings-topbar-btns"
          size="sm"
          title="全屏"
          @click="triggerNavbarHidden()"
      >
        <feather-icon icon="AirplayIcon"/>
      </b-button>
      <b-button
          v-b-modal.share-modal
          v-b-tooltip.hover
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="btn-icon settings-topbar-btns"
          size="sm"
          title="分享"
          variant="warning"
      >
        <feather-icon icon="Share2Icon"/>
      </b-button>
      <share-modal
          :key="'panel-topbar-share-modal' + $route.params.uid"
      ></share-modal>
      <b-button
          v-b-tooltip.hover
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="btn-icon settings-topbar-btns"
          size="sm"
          title="添加组件"
          variant="success"
          @click="group_sidebar_show"
      >
        <feather-icon icon="PlusIcon"/>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import useGroupTopbar from "./GroupTopbarUse";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ShareModal from "../ShareModal/ShareModal";
import {computed, ref, watch} from "@vue/composition-api";
import {panel_group_default} from "@/views/panel-group/defaultSettings";
import {isObjectChanged} from "@/libs/utils/isObjectChanged";
import {showToast} from "@/libs/utils/showToast";
import store from "@/store";
import {routerParams} from "@/libs/utils/routerParams";

export default {
  name: "GroupTopbar",
  props: ['group-topbar-settings'],
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    ShareModal,
  },
  setup(props, {emit}) {
    const project_id = computed(() => {
      return routerParams("project_id");
    });

    const {
      y,
      group_time_query_modal,
      time_query_unit_list,
      isVerticalMenuHidden,
      group_sidebar_show,
      triggerNavbarHidden,
    } = useGroupTopbar();

    let group_auto_refresh_interval_timer = ref(null)
    let group_auto_refresh_count_down = ref(null)
    let group_topbar_settings = ref(Object.assign({}, panel_group_default))

    watch(() => props['groupTopbarSettings'], (val) => {
      //若props中的值发生变化，则更新本地的值
      if (isObjectChanged(val, group_topbar_settings.value)) {
        group_topbar_settings.value = val
        if (val.group_auto_refresh_active) {
          setGroupAutoRefreshInterval()
        }

        console.log('inner-----group-topbar-settings', val)
      }
    }, {deep: true})
    //若本地的值发生变化，则更新父组件的值
    // watch(() => group_topbar_settings.value, (val) => {
    //   if (isObjectChanged(val, props['groupTopbarSettings'])) {
    //     emit('update:groupTopbarSettings', val)
    //     console.log('emit-----group-topbar-settings', val)
    //   }
    //
    // }, {deep: true})

    // watch(
    //     () => group_topbar_settings.value.group_time_query_active,
    //     (value) => {
    //       if (value) {
    //         group_time_query_modal.value = true;
    //       }
    //     }
    // );

    //刷新页面数据
    const refreshQueryData = () => {
      emit('refresh-query-data')
      showToast(null, '提示', '正在刷新页面数据')
    }

    //更新自动刷新设置
    const updateGroupAutoRefreshSettings = () => {
      const group_id = group_topbar_settings.value.group_id;
      if (group_id) {
        const payload = {
          project_id: project_id.value,
          group_id: group_id,
          group_auto_refresh_active: group_topbar_settings.value.group_auto_refresh_active,
          group_auto_refresh_interval: group_topbar_settings.value.group_auto_refresh_interval,
        };
        store
            .dispatch("panel-group/updatePanelGroup", payload)
            .then((response) => {
              showToast(null, '成功', '更新参数成功')
              //立即刷新一次数据，然后开启自动刷新定时器
              refreshQueryData()
              setGroupAutoRefreshInterval()
            })
            .catch((error) => {
              showToast(error);
            });
      } else {
        showToast(null, '错误', '更新参数失败，请刷新页面后重试')
      }
    }

    //设置自动刷新定时器
    const setGroupAutoRefreshInterval = () => {
      //无论是否要开启自动刷新，都先清除定时器
      if (group_auto_refresh_interval_timer.value) {
        clearInterval(group_auto_refresh_interval_timer.value)
      }
      if (group_topbar_settings.value.group_auto_refresh_active) {
        group_auto_refresh_count_down.value = group_topbar_settings.value.group_auto_refresh_interval || 3600
        group_auto_refresh_interval_timer.value = setInterval(() => {
          group_auto_refresh_count_down.value = group_auto_refresh_count_down.value - 1
          if (group_auto_refresh_count_down.value <= 0) {
            refreshQueryData()
            group_auto_refresh_count_down.value = group_topbar_settings.value.group_auto_refresh_interval
          }
        }, 1000)
      }
    }

    return {
      y,
      time_query_unit_list,
      isVerticalMenuHidden,
      group_sidebar_show,
      triggerNavbarHidden,
      group_topbar_settings,
      group_time_query_modal,
      refreshQueryData,
      updateGroupAutoRefreshSettings,
      group_auto_refresh_count_down
    };


  },
};
</script>

<style lang="scss" scoped>
.settings-topbar-btns {
  margin-left: 0.5rem;
}

::v-deep label.custom-control-label {
  margin-bottom: 0 !important
}
</style>
